<template>
  <div style="margin-top: 100px">
    <BasePageBreadCrumb :pages="pages" :title="` Detalhes do arquivo`" />

    <div class="container-fluid qsuite-c-fluid-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-7">
            <div class="card card-bg-trans">
              <div class="card-body pt-2 px-0">
                <form
                  @submit="save"
                  class="px-2 needs-validation"
                  novalidate=""
                >
                  <div class="form-body">
                    <div class="row">
                      <div class="col-12 pb-2 pt-2">
                        <label>Empreendimento </label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon2">
                              <i class="fas fa-search"></i>
                            </div>
                          </div>
                          <input
                            v-model="enterpriseSearch"
                            type="text"
                            class="form-control"
                            placeholder="Procurar"
                            aria-label="Input group example"
                            aria-describedby="btnGroupAddon2"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          data-spy="scroll"
                          data-offset="0"
                          class="position-relative mt-2 scroll-1"
                        >
                          <div class="col-12">
                            <div class="row">
                              <div
                                v-for="item in enterprises"
                                class="col-12 col-md-6 col-lg-4"
                              >
                                <div class="custom-control custom-radio">
                                  <input
                                    :value="item.id"
                                    type="radio"
                                    v-model="enterpriseFile.enterprise_id"
                                    :id="`enterprise_${item.id}`"
                                    name="enterprise_id"
                                    class="custom-control-input"
                                  />
                                  <label
                                    class="custom-control-label check-label"
                                    :for="`enterprise_${item.id}`"
                                    >{{ item.name }}</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-12 pb-2 mt-4">
                        <label>Corretores</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text" id="btnGroupAddon2">
                              <i class="fas fa-search"></i>
                            </div>
                          </div>
                          <input
                            v-model="brokerSearch"
                            type="text"
                            class="form-control"
                            placeholder="Procurar"
                            aria-label="Input group example"
                            aria-describedby="btnGroupAddon2"
                          />
                        </div>
                      </div> -->
                      <!-- <div class="col-12">
                        <div
                          data-spy="scroll"
                          data-offset="0"
                          class="position-relative mt-2 scroll-1"
                        >
                          <div class="col-12">
                            <div
                              v-for="item in brokers"
                              class="custom-control custom-checkbox"
                            >
                              <input
                                type="checkbox"
                                :checked="item.checked"
                                @click="() => brokerChangedChange(item)"
                                class="custom-control-input"
                                :id="`broker${item.id}`"
                              />
                              <label
                                class="custom-control-label check-label"
                                :for="`broker${item.id}`"
                                >{{ item.first_name }}</label
                              >
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div class="col-12">
                        <div class="form-group">
                          <label for="enterprise">Tipo de envio</label>
                          <select
                            required
                            v-model="enterpriseFile.enterprises_files_types_id"
                            class="custom-select mr-sm-2"
                            id="enterprise"
                            @change="onlyAllowImages()"
                          >
                            <option value="0" selected="">Selecione</option>
                            <option value="1">Tabela comercial do mês</option>
                            <option value="2">Kit marketing</option>
                            <option value="3">Imagens e plantas</option>
                            <option value="4">Documentos</option>
                            <option value="5">Vídeos</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-12 col-md-12 col-lg-12 pt-4">
                        <div class="form-group">
                          <label>Descrição </label>
                          <input
                            required
                            v-model="file_unit.description"
                            class="form-control"
                          />
                        </div>
                      </div>
                      <!-- <div class="col-12 col-md-6 ml-auto">
                        <div class="form-group">
                          <label>Agendar para excluir</label>
                          <input
                            v-model="file_unit.date_hour_delete"
                            type="datetime-local"
                            class="form-control"
                          />
                          <small
                            id="name13"
                            class="badge badge-default badge-danger form-text text-white"
                            >Será excluído totalmente da plataforma</small
                          >
                        </div>
                      </div> -->
                      <div class="col-12 pt-4" v-if="enterpriseFile.enterprises_files_types_id!=5">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Upload</span>
                            </div>
                            <div class="custom-file">
                              <input
                                required
                                type="file"
                                class="custom-file-input"
                                @change="setFile"
                                id="inputGroupFile01"
                              />
                              <label
                                class="custom-file-label"
                                for="inputGroupFile01"
                                >{{ file_unit.file.name }}</label
                              >
                            </div>
                            {{file_unit.name}} 
                          </div>
                        </div>

                        <div class="col-lg-8 mt-5" v-if="this.enterpriseFile.file_picture == true">
                            <img style="width: 200px" id="file0" :src="enterpriseFile.file" />
                        </div>
                      </div>
                      <div class=" mt-5 col-12" v-if="enterpriseFile.enterprises_files_types_id==5">
                      <p>Vídeo</p>
                          <!-- <button type="button" class="btn btn-primary mb-3 d-block" @click="adicionarLink()">adicionar link</button>  -->
                          <input type="text" class="form-control w-70 mt-2 mb-2" v-model="enterpriseFile.file" />
                           <!-- <button type="button" class="btn mt-2 mb-2 btn-secondary" @click="removerLink(id)">remover</button> -->
                     
                      </div>
                    </div>
                  </div>
                  <div class="form-actions">
                    <div class="text-right">
                      <button type="reset" class="btn btn-dark">
                        Cancelar
                      </button>
                      <button type="submit" class="btn btn-info">
                        Salvar alterações
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-5 p-0">
            <div class="col-12">
              <div class="card-list py-0" v-if="enterpriseSelected">
                <div class="card-body-list py-0">
                  <div class="col-12 p-0 align-self-center">
                    <div class="col-12">
                      <div class="row">
                        <div
                          v-show="enterpriseSelected.image"
                          class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img"
                        >
                          <img
                            :src="enterpriseSelected.image"
                            alt="user"
                            class="rounded-circle card-body-list-img"
                          />
                        </div>
                        <div
                          class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center"
                        >
                          <h6 class="card-body-list-title d-none d-lg-block">
                            {{ enterpriseSelected.name }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
     <script>
import EnterpriseFileService from "@/services/resources/EnterpriseFileService";
import BrokerService from "@/services/resources/BrokerService";
import EnterpriseService from "@/services/resources/EnterpriseService";
const serviceEnterpriseFile = EnterpriseFileService.build();
const brokerService = BrokerService.build();
const service = EnterpriseService.build();

export default {
  data() {
    return {
      enterpriseSearch: "",
      all_enterprises: [],
      enterprises: [],
      brokerSearch: "",
      youtubelinks: [''],
      all_brokers: [],
      brokers: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0,
      },
      pages: [
        {
          name: "dashboard",
          to: "Dashboard",
        },
        {
          name: "imóveis",
          to: "Enterprise",
        },
      ],
      quantity: 1,
      enterpriseFile: {
        enterprises_files_types_id: 0,
        file_picture: false,
        enterprise_id: null,
        brokers: [
          {
            id: 0,
            checked: false,
          },
        ],
      },
      file_unit: {
        description: null,
        date_hour_delete: null,
        file: {
          name: "ANEXAR ARQUIVO"
        },
      },
      files: [],
    };
  },
  methods: {
    setFile(eve) { 
        
        this.enterpriseFile.file =  eve.target.files[0]

     
            var reader = new FileReader();
            reader.onload = function (eve2) {               
                $('#file0').attr('src', eve2.target.result);
            }          
            reader.readAsDataURL( this.enterpriseFile.file);
    

     
      this.file_unit.file.name = eve.target.files[0].name;
    },
    save(e) {
      e.preventDefault();

      // if (typeof this.enterpriseFile.file === 'string' || this.enterpriseFile.file instanceof String) {
      //   this.$bvToast.toast("Faça upload de algum arquivo", {
      //       title: "Escolha um arquivo",
      //       autoHideDelay: 5000,
      //       type: "error",
      //     });
      //   return false
      // }

      let data = new FormData();
      let brokersTreated = [];

      // this.brokers.forEach((item) => {
      //   if (item.checked && item.id) {
      //     brokersTreated.push(item);
      //   }
      // });

        
       this.all_brokers.forEach(item => {
          // brokersTreated[brokersTreated.length]=item.id         
          brokersTreated[brokersTreated.length]=item         
       });

      // this.all_brokers.forEach((item) => {
      //   if (item.checked && item.id) {
      //     brokersTreated.push(item);
      //   }
      // });
    if(this.enterpriseFile.enterprises_files_types_id!=5){
        data.append("description", this.file_unit.description);
        data.append("date_hour_delete", this.file_unit.date_hour_delete ?? "");
        data.append(
          "enterprises_files_types_id",
          this.enterpriseFile.enterprises_files_types_id
        );
        data.append("enterprise_id", this.enterpriseFile.enterprise_id);
        data.append("file0", this.enterpriseFile.file);
        data.append("quantity", this.quantity);
        data.append("brokers", JSON.stringify(brokersTreated));
        data.append("id", this.$route.params.id);
    }else{
           data.append("youtubelink0",  this.enterpriseFile.file)    
          data.append("quantity",  this.youtubelinks.length) 
           data.append("description", this.file_unit.description);
          data.append("enterprises_files_types_id","5")    
          data.append("enterprise_id", this.enterpriseFile.enterprise_id);  
          data.append("brokers", JSON.stringify(brokersTreated));
           data.append("id", this.$route.params.id);
    }

      serviceEnterpriseFile
        .create(data)
        .then((resp) => {
          this.$bvToast.toast("Arquivo atualizado com sucesso!", {
            title: "Arquivo adicionado",
            autoHideDelay: 5000,
            type: "success",
          });
        })
        .catch((err) => {
          if (err.name != null && err.name == "Error") {
              this.$bvToast.toast('Erro ao adicionar arquivos! Tente novamente ou entre em contato com o suporte', {
                  title: 'Arquivos não adicionados',
                  autoHideDelay: 5000,
                  type: 'error'
              })
          }
        })
        .finally(() => {});
    },
    brokerChangedChange(value) {
      let copyBrokers = [];

      if (+value.id == 0 && !value.checked) {
        this.brokers.map((item) => {
          copyBrokers.push({ ...item, checked: true });
        });
      } else if (+value.id == 0 && value.checked) {
        this.brokers.map((item) => {
          copyBrokers.push({ ...item, checked: false });
        });
      } else {
        this.brokers.map((item) => {
          if (item.id === value.id) {
            copyBrokers.push({ ...item, checked: !item.checked });
          } else {
            copyBrokers.push(item);
          }
        });
      }

      this.brokers = copyBrokers;
    },
    onlyAllowImages() {
        if ($("#enterprise").val() == 3) {
            document.getElementById("inputGroupFile01").setAttribute("accept", "image/*");
        } else {
            document.getElementById("inputGroupFile01").removeAttribute("accept");
        }
    },
    loadFileExtenstion(file) {
      return file.split(".").pop()
    },
    fetchBrokers() {
      let data = { all: 1 };
      brokerService
        .search(data)
        .then((resp) => {
        
          resp = resp.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
          this.brokers = resp;
          this.all_brokers = resp;
        })
        .catch((err) => {});
    },
    fetchEnterpriseFiles(params = null) {
      let data = params ?? { id: this.$route.params.id };
      serviceEnterpriseFile.read(data).then((resp) => {
        this.enterpriseFile = resp;
       
        this.file_unit = {
          ...this.file_unit,         
          description: resp.description,
          date_hour_delete: resp.date_hour_delete,
        };
        this.file_unit.file.name = resp.name;
        this.enterpriseFile.file = resp.path_name

        let brokersTreated = [];
        
        if (this.loadFileExtenstion(this.enterpriseFile.file) == "jpg" || this.loadFileExtenstion(this.enterpriseFile.file) == "png"
        || this.loadFileExtenstion(this.enterpriseFile.file) == "jpeg" || this.loadFileExtenstion(this.enterpriseFile.file) == "bpm"
        || this.loadFileExtenstion(this.enterpriseFile.file) == "svg") {
          this.enterpriseFile.file_picture = true
        }
     
        if (this.enterpriseFile.enterprises_files_types_id == 3) {
          document.getElementById("inputGroupFile01").setAttribute("accept", "image/*");
        }

        for (let i = 0; i < this.all_brokers.length; i++) {
          let checked = false;
          for (let j = 0; j < resp.brokers.length; j++) {
            if (+this.all_brokers[i].id === +resp.brokers[j].broker_id) {
              checked = true;
              break;
            }
          }

          brokersTreated.push({
            ...this.all_brokers[i],
            checked,
          });
        }

        this.brokers = brokersTreated;
      });
    },
    removerLink(id){
        this.youtubelinks.splice(id, 1)
    },
    adicionarLink(){
       // this.youtubelinks[this.youtubelinks.length]='' 
     this.$set(this.youtubelinks, this.youtubelinks.length, '')
    },
    fetchEnterprises() {
      service
        .search()
        .then((resp) => {
          this.enterprises = resp;
          this.all_enterprises = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  mounted() {
    this.fetchBrokers();
    this.fetchEnterprises();
    this.fetchEnterpriseFiles();
  },
  computed: {
    enterpriseSelected() {
      let enterpriseTreated = null;

      this.all_enterprises.forEach((item) => {
        if (item.id === this.enterpriseFile.enterprise_id) {
          enterpriseTreated = item;
        }
      });

      return enterpriseTreated;
    },
  },
  watch: {
    enterpriseSearch() {
      let enterprisesFiltered = [];
      if (!this.enterpriseSearch.length) {
        this.enterprises = this.all_enterprises;
        return;
      }
      this.all_enterprises.forEach((enterprise) => {
        if (enterprise.name.toLowerCase().includes(this.enterpriseSearch)) {
          enterprisesFiltered.push(enterprise);
        }
      });

      this.enterprises = enterprisesFiltered;
    },
    brokerSearch() {
      let brokersFiltered = [];
      if (!this.brokerSearch.length) {
        this.brokers = this.all_brokers;
        return;
      }
      this.all_brokers.forEach((broker) => {
        if (broker.first_name.toLowerCase().includes(this.brokerSearch)) {
          brokersFiltered.push(broker);
        }
      });

      this.brokers = brokersFiltered;
    },
  },
};
</script>
     <style scoped lang="scss">
</style>